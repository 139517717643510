html,body{
    margin: 0;
}
div{
    margin: 0
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .pause {
        animation-play-state : paused;
    }
}